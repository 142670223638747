
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import swipe from '@/directives/swipe';
    import Button from '@/components/blocks/Button.vue';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';

    export interface Card {
        img: Image[];
        title?: string;
        href: string;
        targetBlank: boolean;
    }

    export interface Image {
        src: string;
        title?: string;
        alt?: string;
    }

    export const meta = {
        title: 'Наша продукция',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Наша продукция'
            },
            cards: {
                title: 'Элементы',
                type: AvailableTypes.array,
                item: {
                    img: { type: AvailableTypes.image, title: 'Картинка' },
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    href: {
                        type: AvailableTypes.string,
                        title: 'Ссылка'
                    },
                    targetBlank: {
                        title: 'Открывать ссылку в новом окне',
                        type: AvailableTypes.boolean
                    }
                },
                default: optionsDefault.Products
            }
        },
        group: AvailableGroups.Плитка
    };

    @Component({
        components: {
            Button,
            InlineSlider,
            ImageComponent,
            OptionsFieldsInlineEditor,
            SliderWrapper
        },
        directives: { swipe }
    })
    export default class Products extends PageBlockMixin {
        @Prop(Array) cards: Card[];
        @Prop() component;
    }
