<script>
    import { Component, Prop } from 'nuxt-property-decorator';
    import { mixins } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        title: 'Ошибка 404',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: '404'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default: 'Сожалеем, но вы ошиблись адресом'
            },
            img: {
                type: AvailableTypes.image,
                title: 'Картинка',
                default: {
                    src: '/block-default/404Error/404.png'
                }
            }
        },
        group: AvailableGroups.Другое
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            ImageComponent
        }
    })
    export default class NotFoundBlock extends mixins(PaletteMixin) {
        @Prop() head;
        @Prop() text;
        @Prop() img;
        @Prop() component;
    }
</script>

<template>
    <section class="notfound-block" :style="backgroundBg">
        <BContainer>
            <BRow>
                <BCol xl="11" lg="12" offset-xl="1">
                    <div class="notfound-block__wrap">
                        <div class="notfound-block__text-wrap">
                            <h1 class="notfound-block__head">
                                <OptionsFieldsInlineEditor
                                    keyOption="head"
                                    :component="component"
                                    :value="head"
                                />
                            </h1>
                            <div class="notfound-block__text">
                                <OptionsFieldsInlineEditor
                                    keyOption="text"
                                    :component="component"
                                    :value="text"
                                />
                            </div>
                        </div>
                        <div class="notfound-block__image-wrap">
                            <ImageComponent
                                v-if="img"
                                :img="img"
                                class="notfound-block__image"
                            />
                        </div>
                        <div class="notfound-block__text notfound-block__text--mobile">
                            <OptionsFieldsInlineEditor
                                keyOption="text"
                                :component="component"
                                :value="text"
                            />
                        </div>
                    </div>
                </BCol>
            </BRow>
        </BContainer>
    </section>
</template>

<style lang="scss" scoped>
    .notfound-block {
        padding: 8.875rem 0 8.75rem;
        font-family: $ff-head;

        &__wrap {
            display: flex;
            max-width: 52rem;
            margin: 0 auto;
        }
        &__text-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding-bottom: 16px;
            transform: translateX(140px);
        }
        &__head {
            font-size: 11.25rem;
            line-height: 1.2;
        }
        &__text {
            font-size: 2rem;
            line-height: 1.2;
            text-align: center;

            &--mobile {
                display: none;
            }
        }
        &__image-wrap {
            width: 27.0625rem;
            height: 27.0625rem;
            text-align: center;
            transform: translateX(10px);
        }
        &__image {
            max-width: 100%;
            display: inline-block;
        }
    }

    @include media-breakpoint-down(md) {
        .notfound-block {
            &__wrap {
                flex-direction: column;
                align-items: center;
            }
            &__text-wrap,
            &__image-wrap {
                transform: none;
            }
            &__text-wrap {
                padding-bottom: 0;
            }
            &__image-wrap {
                margin-bottom: 2.25rem;
            }
            &__text {
                display: none;

                &--mobile {
                    display: block;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .notfound-block {
            padding: 5.75rem 0 4.375rem;

            &__image-wrap {
                width: 60%;
                height: auto;
            }

            &__head {
                margin-bottom: 0.75rem;
                font-size: 4.5rem;
            }

            &__text {
                &--mobile {
                    font-size: 1.125rem;
                }
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .notfound-block {
            &__image-wrap {
                width: 73%;
            }
        }
    }
</style>
