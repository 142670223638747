
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import { mixins } from 'nuxt-property-decorator';
    import Button from '@/components/blocks/Button.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import PaletteMixin from '@/mixins/paletteMixin';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import Form from '@/components/blocks/Form.vue';

    export interface Item {
        number: string;
        price: string;
    }

    export const meta = {
        title: 'Цены по колонкам',
        options: {
            title: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Цены на базовую футболку'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    'Стоимость без печати. Конечная цена зависит от сроков, материалов, обработки материалов, количества изделий \n' +
                    'и текущего курса.'
            },
            countName: {
                type: AvailableTypes.string,
                title: 'Единицы',
                default: 'шт.'
            },
            currency: {
                type: AvailableTypes.string,
                title: 'Валюта',
                default: 'руб'
            },
            items: {
                title: 'Элементы',
                type: AvailableTypes.array,
                item: {
                    number: {
                        type: AvailableTypes.string,
                        title: 'Количество'
                    },
                    price: {
                        type: AvailableTypes.string,
                        title: 'Цена'
                    }
                },
                default: [
                    {
                        number: '100',
                        price: '430'
                    },
                    {
                        number: '500',
                        price: '400'
                    },
                    {
                        number: '1000',
                        price: '380'
                    }
                ]
            },
            button: {
                type: AvailableTypes.string,
                title: 'Надпись на кнопке',
                default: 'Заказать расчет'
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        group: AvailableGroups.Цены
    };

    @Component({
        components: { Form, OptionsFieldsInlineEditor, Button }
    })
    export default class PricesColumn extends mixins(PageBlockMixin, PaletteMixin) {
        @Prop(Array) items: Item[];
        @Prop() component;
        @Prop() title;
        @Prop() button;
        @Prop() form;
        @Prop() currency;
        @Prop() countName;
    }
