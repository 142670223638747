
    import { Component, Prop } from 'nuxt-property-decorator';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import { PageComponentOptions } from '@/store/page';
    import swipe from '@/directives/swipe';
    import Button from '@/components/blocks/Button.vue';
    import striptags from 'striptags';

    export const meta: ComponentMeta = {
        title: 'Карусель с превью - наши работы',
        options: {
            head: {
                title: 'Заголовок',
                type: AvailableTypes.string
            },
            buttonText: {
                type: AvailableTypes.string,
                title: 'Текст кнопки'
            },
            buttonLink: {
                type: AvailableTypes.string,
                title: 'Ссылка кнопки'
            },
            targetBlank: {
                title: 'Открывать ссылку в новом окне',
                type: AvailableTypes.boolean,
                default: false
            },
            groups: {
                title: 'Изображения',
                type: AvailableTypes.array,
                item: {
                    img: {
                        type: AvailableTypes.image,
                        title: 'Изображение'
                    }
                }
            }
        },
        group: AvailableGroups.Галерея
    };

    @Component({
        components: { ImageComponent, SliderWrapper, Button },
        directives: { swipe }
    })
    export default class OurWorksSlider extends PageBlockMixin {
        @Prop(Array) groups: PageComponentOptions[];
        @Prop() buttonText;
        @Prop() buttonLink;
        @Prop() targetBlank;
        striptags = striptags;

        target() {
            return this.targetBlank ? '_blank' : '_self';
        }
    }
