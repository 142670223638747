var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.groups)?_c('section',{staticClass:"works-slides__slider"},[_c('div',{staticClass:"container"},[(_vm.head)?_c('h2',{staticClass:"h2 h2_center"},[_vm._v(_vm._s(_vm.head))]):_vm._e(),_vm._v(" "),_c('SliderWrapper',{attrs:{"slides":_vm.groups,"start-from":2,"has-preview":true},scopedSlots:_vm._u([{key:"default",fn:function({
                    slides,
                    previewImages,
                    prev,
                    next,
                    getNextIndex,
                    getPrevIndex,
                    getClass,
                    goToSlide,
                    currentIndex
                }){return [_c('div',[_c('b-row',[_c('b-col',{staticClass:"col-lg-10 offset-lg-1"},[_c('div',{staticClass:"works-slides__slider"},_vm._l((slides),function({ img },index){return _c('div',{directives:[{name:"swipe",rawName:"v-swipe:left",value:(next),expression:"next",arg:"left"},{name:"swipe",rawName:"v-swipe:right",value:(prev),expression:"prev",arg:"right"}],key:index,staticClass:"works-slides__slider__content",class:getClass(index)},[_c('image-component',{staticClass:"works-slides__slider__img",attrs:{"src":img}}),_vm._v(" "),_c('div',{staticClass:"works-slides__slider__bullets-wrap"},_vm._l((slides.length),function(i){return _c('div',{key:i,staticClass:"works-slides__slider__bullets",class:{
                                                active: i - 1 === currentIndex
                                            }})}),0)],1)}),0),_vm._v(" "),(slides.length)?_c('div',[_c('div',{staticClass:"works-slides_navigate-values"},[_vm._v("\n                                    "+_vm._s(currentIndex + 1)+" /\n                                    "+_vm._s(slides.length)+"\n                                ")])]):_vm._e(),_vm._v(" "),(slides.length)?_c('div',{staticClass:"works-slides__nav"},[_c('button',{staticClass:"works-slides__nav-btn left",on:{"click":prev}}),_vm._v(" "),_c('button',{staticClass:"works-slides__nav-btn right",on:{"click":next}})]):_vm._e(),_vm._v(" "),_c('transition-group',{staticClass:"works-slides__preview__content-wrap d-none d-lg-flex",attrs:{"tag":"div"}},_vm._l((previewImages),function({ src, key },index){return _c('div',{key:`item-${key}`,staticClass:"works-slides__preview__content",class:{
                                        prev: index === 0,
                                        next: index === previewImages.length - 1,
                                        active:
                                            index > 0 &&
                                            index < previewImages.length - 1
                                    }},[_c('image-component',{staticClass:"works-slides__preview__img",attrs:{"src":src}})],1)}),0),_vm._v(" "),(_vm.buttonText)?_c('div',{staticClass:"works-slides__button"},[_c('Button',{attrs:{"link":_vm.buttonLink,"text":_vm.buttonText,"target-blank":_vm.target()}})],1):_vm._e()],1)],1)],1)]}}],null,false,1472855680)})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }