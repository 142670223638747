
    import { Component, Prop, Vue } from 'nuxt-property-decorator';

    @Component
    export default class Modal extends Vue {
        @Prop() name;
        @Prop() isSmall;
        @Prop({ default: false }) custom;

        mounted() {
            this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
                let evt = document.createEvent('UIEvents');
                // @ts-ignore
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
            });
        }

        get classDialog() {
            if (this.isSmall) {
                return 'popup-form__wrap-small';
            }

            return 'popup-form__wrap';
        }
    }
